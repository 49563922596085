<template>
    <div>
        <div class="p-1">
        <sub-header
    :backButton="true"
   ></sub-header></div>
        <div class="p-1">
        <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col">
        <div class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
            Edit Tenant
        </div>
        <div class="p-3">
        <FormulateForm >
            <div class="grid grid-cols-2 gap-x-6">
                <FormulateInput class="w-full" label="Tenant name" placeholder="Tenant name" type="text" v-model="tenantInformation.title" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput  class="w-full" label="Domain Name" placeholder="Domain Name" disabled type="text" v-model="tenantInformation.domain_name" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput  class="w-full" label="Onboarding date" placeholder="Onboarding date" disabled type="date" v-model="tenantInformation.created_at" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput  class="w-full" label="Account Number" placeholder="Account Number" type="text" v-model="tenantInformation.account_number" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput class="w-full" label="Website" placeholder="Website" type="url" v-model="tenantInformation.website" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput class="w-full" label="Registration number" placeholder="Company registration number" type="text" v-model="tenantInformation.registration_number" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput class="w-full" label="Tenant client action alert emails" placeholder="Final report alert emails" type="pills" v-model="tenantInformation.final_report_alert_emails" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                <FormulateInput class="w-full" label="Telephone" placeholder="Telephone" type="text" v-model="tenantInformation.telephone" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
            </div>
            
           <div class="grid grid-cols-2 gap-6">
                <FormulateInput type="image" name="Tenant logo" :uploader="uploadClientLogo" @file-removed="removeClientLogo()" :value="tenantInformation.logo_url" label="Tenant Logo" help="Select a png, jpg, jpeg or svg to upload." validation="mime:image/jpeg,image/png,image/svg+xml,image/jpeg" :maxSize="50" :validation-messages="{ mime: 'Client logo must be of type jpg/jpeg svg, png' }" />
            </div>
        </FormulateForm>
        <div class="flex justify-end max-w-full">
                <Button @click="saveTenantDetails()" class="btn-primary float-right mt-4" text="Save" />
            </div>
        </div>
        </div>
        </div>
    </div>
</template>

<script>
import SubHeader from "@/components/SubHeader"
import {getTenantDetails, editTenantDetails} from "./services"
import Button from "@/components/button";
export default {
    name: "edit-tenant",
    data: function() {
        return {
             tenantInformation: {
                id: null,
                title: null,
                created_at: null,
                account_number: null ,
                website: null,
                registration_number: null,
                final_report_alert_emails: [],
                client_action_alert_emails: [],
                telephone: null,
                logo_url: null,
                domain_name: null,
                key: "client_information"
            },
        }
    },
    components: {
        SubHeader,
        Button
    },
    async mounted() {
        await this.setTenantDetails(this.$route.params.id)
    },
    methods: {
        async setTenantDetails(tenantId) {
            const {data} = await getTenantDetails(tenantId)
            this.tenantInformation = data
        },
        uploadClientLogo() {

        },
        async saveTenantDetails() {
            const payload = {
                id: this.tenantInformation.id,
                title: this.tenantInformation.title,
                account_number: this.tenantInformation.account_number,
                registration_number: this.tenantInformation.registration_number,
                telephone: this.tenantInformation.telephone,
                website: this.tenantInformation.website
            }
            await editTenantDetails(payload)
        }
    }
    
}
</script>