import axios from "@/axios"

export async function getTenantDetails(tenantId) {
    const url =`delvium-admin/tenant/${tenantId}`
    return await axios.get(url)
}


export async function editTenantDetails(payload, tenant_id) {
    const url = `/tenant/${tenant_id}`
    return await axios.put(url, payload) 
}

export async function getAllDashboards() {
    const url = '/dashboard/get-all-dashboard'
    return await axios.get(url)
}

export async function getTenantDashboards(tenantId) {
    const url = `/dashboard/assigned-dashboards?tenant_id=${tenantId}`
    return await axios.get(url)
}